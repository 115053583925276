import React, { useEffect, useContext } from 'react';
import { SimulatorContext } from "src/components/Simulator/SimulatorContext";
import * as styles from "./styles.landing.module.css";

const Landings = ({ params }) => {
    const country = typeof window !== 'undefined' ? window.domainData.countryCode : 'cl';
    const urlBaseLanding = `https://productos.cumplo.${country}/`;
    const landingName = params["*"];

/* descomentar si logro solucionar tema de cors si no eleminar y dejar versión actual cmo definitiva
    useEffect(() => {
        const iframe = document.querySelector('#hubspot-landing');

        const resizeIframe = () => {
            iframe.style.height = iframe.contentWindow.document.documentElement.scrollHeight + 'px';
        };

        iframe.addEventListener('load', resizeIframe);

        return () => {
            iframe.removeEventListener('load', resizeIframe);
        };
    }, []);*/

    return (
        <div className={styles.landingContainer}>
            <iframe id="hubspot-landing" className={styles.landing} src={urlBaseLanding + landingName} />
        </div>
    );
};

export default Landings;
